

export function isEmpty(data) {

    let trim_data = data.map((s) => s.toString().trim());
    // data.map(function(s:any) { return s.trim() });
    if (trim_data.includes("")) {
        return true
    }
    else {
        return false
    }

}
