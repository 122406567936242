import React, { Component } from "react";
import 'bootstrap/dist/css/bootstrap.min.css'; //npm install --save bootstrap
import exportFromJSON from 'export-from-json'; // npm i --save export-from-json
import './FileParser.css';
import { TextField, Button, FormControl, InputLabel, LinearProgress, Select, CircularProgress, MenuItem, Fab, ListItemText, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper, Tooltip } from '@material-ui/core';
import { AttachFile, MoveToInbox, ExpandMore, GetApp, Description, Label } from '@material-ui/icons'

import { get, post } from '../custom_libraries/serverRequests';
import urls from '../custom_libraries/urls';
import globalVariables from '../custom_libraries/globalVariables';
import { isEmpty } from '../custom_libraries/validation';

import yun_logo from '../images/yunlogo.png'

class TabNewsReader extends Component {

    constructor(props) {
        super(props);
        this.file_chooser = React.createRef();
    }

    state = {
        news: '',
        banks_involved: '----',
        client: '----',
        country: '----',
        interest_rate: '----',
        currency: '----',
        deal_size: '----',
        deadline: '----',
        deal_type: '----',
        news_containt: '',
        all_data: [],

        is_progressar_hidden: true,
        spinner_hidden: true,
        button_disabled: false,
        download_hidden: true,
    }

    news_list = [
        {
            id: 1,
            title: 'Battery maker Leoch International Technology Ltd has launched a US$100m three-year borrowing for refinancing.',
            description: `China CITIC Bank International, Hang Seng Bank and HSBC are the mandated lead arrangers and bookrunners of the transaction, which pays an interest margin of 270bp over Libor and carries an average life of 2.25 years.
                            Lead arrangers with commitments of US$20m or more receive a top-level all-in of 340bp via an upfront fee of 147.5bp, while arrangers taking US$10m–$19m earn an all-in of 328bp based on a fee of 120.5bp.
                            The all-in calculations include an early bird fee of 10bp offered to banks committing by June 5. The final commitment deadline is June 12.
                            The borrower’s six subsidiaries form the guarantor group.
                            Funds are to refinance the outstanding portion of a US$200m three-year loan the borrower raised in May 2017.
                            The same three banks were also the MLABs on that deal, which offered a top-level all-in pricing of 294.89bp via a margin of 250bp over Libor and a 2.45-year average life. (May 4 2017 story)
                            Leoch International Technology is a Hong Kong-listed investment holding company that manufactures lead-acid batteries. It operates businesses in China, Europe, the United States and other countries.
                            ((Evelynn Lin: +852 2912 6607, evelynn.lin@refinitiv.com, Twitter: @LPCLoans))
                            (Editing by Prakash Chakravarti)`
        },
        {
            id: 2,
            title: 'Cheng Uei Precision Industry Co Ltd has launched a NT$3.045bn (US$102m) five-year guarantee facility.',
            description: `CTBC Bank is the sole mandated lead arranger and bookrunner of the transaction, which pays an annual guarantee fee of 65bp.
                            Funds are to back the planned issuance of corporate bonds.
                            MLAs joining with commitments of NT$1.015bn or above receive an upfront fee of 5bp.
                            The deadline for commitments is May 29.
                            Cheng Uei's previous loan market visit was in June last year for a slightly smaller NT$3.036 five-year guarantee facility. CTBC was also the MLAB of the borrowing, which offers an annual guarantee fee of 65bp with a commitment fee of 10bp. (June 12, 2019 story)
                            Established in 1986, the Taiwan-listed borrower makes components for computers and mobile phones, including cable assemblies and battery packs.
                            ((Aileen Chuang: +852 3761 1934, aileen.chuang@refinitiv.com, Twitter: @LPCLoans))
                            (Editing by Prakash Chakravarti)`
        },
        {
            id: 3,
            title: 'Electronic components maker Heran Co Ltd has launched a NT$6.47bn (US$216m) five-year loan.',
            description: `Hua Nan Commercial Bank is the mandated lead arranger and bookrunner of the deal, which comprises four tranches intended for different purposes.
                            Each tranche is further split into two portions – one for bank investors and another for bills finance firms.
                            The tranches for banks offer interest margins of 80bp over the one-year post office savings rate, with a pre-tax interest rate floor of 1.70%.
                            The portions for the bills finance firms pay margins of 54bp over the one-year post office savings rate, with the pre-tax interest rate floor at 1.55%.
                            MLAs joining with NT$900m or more earn upfront fees of 15bp, while participants with NT$400m–$900m receive 10bp.
                            The deadline for responses is June 12.
                            The borrower's land serves as security.
                            Proceeds will refinance a NT$2.47bn five-year loan in February last year, working capital and construction financing purposes.
                            The February 2019 loan offers a margin of 85bp over the one-year post office savings rate. Hua Nan Commercial Bank was also the sole MLAB for that deal, which marked Heran's debut in the syndicated loan market. (February 1, 2019 story)
                            Last May, Heran raised NT$911m from an initial public offering in Taiwan.
                            The company, founded in 2002, produces liquid crystal displays, display screens, air conditioning units and other household appliances.
                            ((Aileen Chuang: +852 3761 1934, aileen.chuang@refinitiv.com, Twitter: @LPCLoans))
                            (Editing by Prakash Chakravarti)`
        },
        {
            id: 4,
            title: 'An African unit of Chinese state-owned China Nonferrous Metal Mining is in the market with a US$300m offshore syndicated loan.',
            description: `China Construction Bank (Asia) is the mandated lead arranger and bookrunner of the bullet transaction, which offers an interest margin of 145bp over Libor.
                            Banks joining with US$50m or above as lead arrangers receive a top-level all-in pricing of 175bp via a 90bp fee, while arrangers with US$30m–$49m earn an all-in pricing of 170bp via a 75bp fee.
                            Zambia-incorporated NFC Africa Mining Plc is the borrowing entity, while its parent, Hong Kong-listed China Nonferrous Mining Corp Ltd, is the guarantor.
                            Proceeds will be used for working capital and refinancing purposes.
                            Commitments are due by June 12.
                            China Nonferrous Mining Corp focuses on the mining, ore processing, leaching, smelting and sale of copper in Zambia. 
                            In 2019, China Nonferrous Mining Corp recorded revenue of US$2bn, representing a 2.2% drop year-on-year, mainly caused by the decline in global copper price.
                            ((Apple Li: +852 2912 6685, apple.li@refinitiv.com, Twitter: @LPCLoans))
                            (Editing by Prakash Chakravarti)`
        },
        {
            id: 5,
            title: 'LCD maker Innolux Corp has launched a NT$30bn five-year loan into general syndication at four ticket levels.',
            description: `CTBC Bank and Mega International Commercial Bank are the mandated lead arrangers and bookrunners.
                            The interest margin ranges from 83bp to 105bp over Taibor and is tied to the borrower's after-tax net profit margin, with the pre-tax interest rate floor set at 1.7% and a commitment fee of 15bp.
                            MLABs joining with NT$5bn or more earn an upfront fee of 30bp, while MLAs coming in with NT$4bn–$4.9bn receive a fee of 20bp. Co-arrangers committing NT$2.5bn–$3.9bn earn an fee of 12bp, while managers with NT$1bn–$2.4bn will receive a 5bp fee.
                            The deadline for commitments is May 29.
                            Proceeds raised are for refinancing and working capital purposes.
                            The borrower last tapped the market in July 2018 for a NT$43.75bn five-year term loan.
                            Bank of Taiwan, Chang Hwa Commercial Bank, CTBC, DBS Bank, Hua Nan Commercial Bank, Land Bank of Taiwan, Mega International Commercial Bank, Taipei Fubon Bank and Taiwan Business Bank were the MLABs on that deal, which offered a margin ranging from 83bp to 105bp based on the borrower's after-tax net profit margin. (July 5 2018 story)
                            Innolux Corp, formerly known as Chimei Innolux Corp, was formed in March 2010 when Innolux Display Corp merged with Chi Mei Optoelectronics Corp and TPO Displays Corp.
                            Innolux's major shareholders are Chi Mei Corp and Hon Hai Precision Industry Co Ltd, a major supplier to Apple Inc.
                            ((Aileen Chuang: +852 3761 1934, aileen.chuang@refinitiv.com, Twitter: @LPCLoans))
                            (Editing by Chien Mi Wong)`
        },
        {
            id: 6,
            title: 'Taiwanese electronics giant HTC Corp is making its debut in the loan market with a NT$9bn (US$302m) dual-tranche new-money financing.',
            description: `CTBC Bank is the mandated lead arranger and bookrunner of the deal, which has a NT$5bn five-year tranche A, a NT$2bn five-year tranche B and a NT$2bn three-year tranche C.
                            The interest margin, based on the borrower’s pre-tax net profit margin, ranges from 82bp to 92bp over Taibor, with the pre-tax interest rate floor set at 1.7%.
                            MLAs joining with NT$2bn or more earn an upfront fee of 15bp, while managers coming in with NT$1bn–$1.9bn receive a 10bp fee.
                            The deadline for responses is May 29.
                            Funds are for working capital purposes.
                            Founded in 1997, HTC is mainly engaged in the design, manufacture and sales of smart phones. It is also involved in virtual reality, wearable devices and Internet of Things businesses. The company distributes its products in domestic and overseas markets such as Europe, the Americas and Asia.
                            ((Evelynn Lin: +852 2912 6607, evelynn.lin@refinitiv.com, Twitter: @LPCLoans))
                            (Editing by Prakash Chakravarti)`
        },
        {
            id: 7,
            title: 'Electronic component maker Luxshare Precision Industry Co Ltd has launched a US$500m three-year revolving credit facility into general syndication.',
            description: `Citigroup, HSBC and Shanghai Pudong Development Bank Hong Kong branch are the mandated lead arrangers and bookrunners of the loan, which offers an interest margin of 135bp over Libor.
                            MLABs joining with US$75m or above earn a top-level all-in pricing of 156.67bp via a 65 fee, while MLAs taking US$50m–$74m receive an all-in of 153.33bp via a 55bp fee. Lead arrangers committing US$25m–$49m earn 150bp all-in via a 45bp fee.
                            Shenzhen-listed Luxshare Precision Industry is the guarantor, while its wholly owned subsidiary Lux Precision Ltd is the borrower.
                            Proceeds will be used for refinancing and general corporate purposes.
                            The borrower's previous visit to the market was in November 2017 for a US$400m three-year revolver, which had 21 lenders participating, including Citigroup, Commerzbank and CTBC Bank as MLABs. (November 14, 2017 story)
                            That facility offered a top-level all-in pricing of 161.67bp based on a margin of 145bp over Libor.
                            In August 2018, Hong Kong-based LuxVisions Innovation Ltd, a unit of Luxshare, raised a US$240m bilateral loan to back its purchase of Taiwanese electronic components supplier Lite-On Technology Corp’s camera parts business. (August 30, 2018 story)
                            Founded in 2004, Luxshare manufactures electronic components such as cables, connectors and motors for consumer devices, automotive and cloud applications.
                            ((Apple Li: +852 2912 6685, apple.li@refinitiv.com, Twitter: @LPCLoans))
                            (Editing by Prakash Chakravarti)`
        }
    ]

    getNewsDescriptionById(id) {
        let news = this.news_list.filter((n) => { return n.id == id });
        return (news[0].description)
    }

    handleNews = (evt) => {
        let id = evt.target.value
        this.setState({ news: id })

        if (id !== null || id !== '' || id !== undefined) {
            this.setState({ news_containt: this.getNewsDescriptionById(id) })
        }
    }

    handleNewsContent = (evt) => {
        this.setState({ news_containt: evt.target.value })
    }

    startProgressBar = () => {
        this.setState({ is_progressar_hidden: false });
        this.setState({ button_disabled: true })
        this.setState({ spinner_hidden: false })
    }

    stopProgressBar = () => {
        this.setState({ is_progressar_hidden: true });
        this.setState({ button_disabled: false })
        this.setState({ spinner_hidden: true })
    }

    isEmptyFields = () => {

        let data = [this.state.news_containt]

        return isEmpty(data)
    }

    jsonData = () => {
        let data = {
            'body': this.state.news_containt,
        }
        return JSON.stringify(data)
    }

    extractInfo = async () => {
        if (this.isEmptyFields()) {
            alert("Fill All Fields !!!")
            return
        }

        this.startProgressBar()

        let headers = {
            'content-type': 'application/json',
        }
        let post_data = this.jsonData()

        let data = await post(urls.NEWS_READER, headers, post_data)

        if (data !== false) {
            // console.log(data)
            if (data.hasOwnProperty('status')) {
                if (data['status'] === true) {
                    this.setState({ banks_involved: data['data']['banks'] })
                    this.setState({ client: data['data']['client'] })
                    this.setState({ country: data['data']['country'] })
                    this.setState({ interest_rate: data['data']['interest'] })
                    this.setState({ currency: data['data']['currency'] })
                    this.setState({ deal_size: data['data']['dealSize'] })
                    this.setState({ deadline: data['data']['deadline'] })
                    this.setState({ deal_type: data['data']['dealType'] })

                    this.setState({ all_data: data['data'] })
                    this.setState({ download_hidden: false })
                }
                else {
                    alert('Fill Proper Information !!!')
                }
            }
        }

        this.stopProgressBar()
    }

    downloadReport = () => {
        let all_data = {}
        let original_keys = ['banks', 'client', 'country', 'interest', 'currency', 'dealSize', 'deadline', 'dealType']
        let renamed_keys = ['Banks Involved', 'Client', 'Country', 'Interest Rate', 'Currency', 'Deal Size', 'Deadline', 'Type of Deal']
        for (let i = 0; i < original_keys.length; i++) {
            all_data[renamed_keys[i]] = this.state.all_data[original_keys[i]]
        }
        const data = [all_data]
        const fileName = 'news_report'
        const exportType = 'xls'
        exportFromJSON({ data, fileName, exportType })
    }

    render() {
        return (
            <div className="d-flex justify-content-center align-items-center pt-0 pb-2">
                <div className="container-fluid border p-2">
                    <LinearProgress color="secondary" className='mb-2' hidden={this.state.is_progressar_hidden} />
                    <div className='row p-2'>
                        <div className='col-md-6'></div>
                        <div className='col-md-6'>
                            <FormControl size="small" variant="outlined" className="w-100" disabled={this.state.button_disabled}>
                                <InputLabel>News Title</InputLabel>
                                <Select autoWidth={false} value={this.state.news} onChange={this.handleNews} label="Company Name">
                                    {Object.keys(this.news_list).map((k, i) => (
                                        <MenuItem style={{ whiteSpace: 'normal' }} key={k} value={this.news_list[i]['id']}>
                                            {this.news_list[i]['title']}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <div className="w-100 text-right pt-2">
                                <Button
                                    variant="contained"
                                    size="small"
                                    className="small_button"
                                    onClick={this.extractInfo}
                                    disabled={this.state.button_disabled}>
                                    <CircularProgress
                                        className="text-white mr-2"
                                        size={15}
                                        hidden={this.state.spinner_hidden}
                                    />
                                    Extract Information
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-6'>
                            <div className='text-right'>
                                <Tooltip title="Download Report" placement='left-start' arrow>
                                    <Fab size="small" className='small_button text-white mr-2' onClick={() => this.downloadReport()} disabled={this.state.button_disabled} hidden={this.state.download_hidden}>
                                        <GetApp fontSize='small' />
                                    </Fab>
                                </Tooltip>
                            </div>
                            <TableContainer component={Paper} className='border mt-2'>
                                <Table size="medium">
                                    {/* <colgroup>
                                        <col width="50%" />
                                        <col width="50%" />
                                    </colgroup> */}
                                    <TableBody>
                                        <TableRow>
                                            <TableCell>
                                                <b>Banks Involved</b>
                                            </TableCell>
                                            <TableCell align="left">
                                                {this.state.banks_involved}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                <b>Client</b>
                                            </TableCell>
                                            <TableCell align="left">
                                                {this.state.client}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                <b>Country</b>
                                            </TableCell>
                                            <TableCell align="left">
                                                {this.state.country}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                <b>Interest Rate</b>
                                            </TableCell>
                                            <TableCell align="left">
                                                {this.state.interest_rate}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                <b>Currency</b>
                                            </TableCell>
                                            <TableCell align="left">
                                                {this.state.currency}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                <b>Deal Size</b>
                                            </TableCell>
                                            <TableCell align="left">
                                                {this.state.deal_size}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                <b>Deadline</b>
                                            </TableCell>
                                            <TableCell align="left">
                                                {this.state.deadline}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                <b>Type of Deal</b>
                                            </TableCell>
                                            <TableCell align="left">
                                                {this.state.deal_type}
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                        <div className='col-md-6' style={{ minHeight: '75vh' }}>
                            <TextField
                                size="small"
                                variant="outlined"
                                label="News Content"
                                multiline
                                rows={22}
                                className='w-100 mt-2'
                                inputProps={{ style: { textAlign: 'left' } }}
                                value={this.state.news_containt}
                                onChange={this.handleNewsContent}
                                disabled={this.state.button_disabled}
                            />

                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default TabNewsReader