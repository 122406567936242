import React, { Component } from "react";
import 'bootstrap/dist/css/bootstrap.min.css'; //npm install --save bootstrap
import exportFromJSON from 'export-from-json'; // npm i --save export-from-json
import './FileParser.css';
import { ListItem, ListItemText, Button, Tooltip, Fab, CircularProgress, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper, LinearProgress, FormControl, InputLabel, Select, MenuItem, ListItemIcon, Collapse } from '@material-ui/core';
import { ExpandLess, ExpandMore, GetApp } from '@material-ui/icons'
import { get, post } from '../custom_libraries/serverRequests';
import urls from '../custom_libraries/urls';
import fund_fact_sheets from '../jsons/fund_fact_sheets.json';
import { Description } from "@material-ui/icons";


class TabFundFactSheets extends Component {

    constructor(props) {
        super(props);
        this.file_chooser = React.createRef();
    }

    state = {
        company_name_list: [],
        company_name: '',

        file_name: '',

        all_data: { 'top_10_holdings': [], 'ratings_allocation': [] },
        list_expand: [false, false],

        is_progressar_hidden: true,
        spinner_hidden: true,
        button_disabled: false,
        download_hidden: true,
    }

    componentDidMount() {
        this.getCompanyName()

        // this.setState({all_data: fund_fact_sheets['Eastspring Investments - Asia Real Estate Multi Asset Income Fund']})
    }

    timeout = (delay) => {
        return new Promise(res => setTimeout(res, delay));
    }

    handleCompanyName = (evt) => {
        let company_name = evt.target.value;
        if (company_name !== null || company_name !== undefined || company_name !== '') {
            this.setState({ company_name: company_name });
            this.getOutputFiles(company_name)
        }
    }

    getCompanyName = async () => {
        this.startProgressBar()
        let company_name_list = ['Eastspring Investments - Asia Real Estate Multi Asset Income Fund', 'PRU Link Global Bond Fund', 'Franklin Templeton']
        this.setState({ company_name_list: company_name_list })
        this.stopProgressBar()
    }

    startProgressBar = () => {
        this.setState({ is_progressar_hidden: false });
        this.setState({ button_disabled: true })
        this.setState({ spinner_hidden: false })
    }

    stopProgressBar = () => {
        this.setState({ is_progressar_hidden: true });
        this.setState({ button_disabled: false })
        this.setState({ spinner_hidden: true })
    }

    getOutputFiles = async (company_name) => {

        this.startProgressBar()
        // let headers = {
        //     'content-type': 'application/json',
        // }

        // let data = await get(urls.ANNUAL_REPORT_EXTRACTION, headers)

        // if (data !== false) {
        //     // console.log(data)
        // }

        // Delay of 8 Seconds
        await this.timeout(5000);
        let file_name = 'static/contract_reader/fund_fact_sheets/' + company_name + '.pdf'
        this.setState({ file_name: file_name })
        this.setState({ all_data: fund_fact_sheets[company_name] })
        this.setState({ download_hidden: false })
        this.stopProgressBar()
    }

    onExpand = (index) => {
        let list_expand = this.state.list_expand
        list_expand[index] = !list_expand[index]
        this.setState({ list_expand: list_expand })
    }

    downloadReport = () => {
        let all_data = this.state.all_data
        let delete_key_list = ['top_10_holdings', 'ratings_allocation', 'ratings_allocation_note']
        // for(let i=0; i<delete_key_list.length; i++){
        //     delete all_data[delete_key_list[i]];
        //     console.log(all_data, this.state.all_data)
        // }
        // const all_data = this.omit(all_data, delete_key_list)
        const data = [this.omit(all_data, delete_key_list)]
        const fileName = this.state.company_name
        const exportType = 'xls'
        exportFromJSON({ data, fileName, exportType })
    }

    omit = (obj, props) => {
        props = props instanceof Array ? props : [props]
        return eval(`(({${props.join(',')}, ...o}) => o)(obj)`)
    }

    render() {
        return (
            <div className="d-flex justify-content-center align-items-center pt-0 pb-2">
                <div className="container-fluid border p-2">
                    <LinearProgress color="secondary" className='mb-2' hidden={this.state.is_progressar_hidden} />
                    <div className='row pb-3 pt-2'>
                        <div className='col-sm-6'>
                            <FormControl size="small" variant="outlined" className="w-100" disabled={this.state.button_disabled}>
                                <InputLabel>Company Name</InputLabel>
                                <Select value={this.state.company_name} onChange={this.handleCompanyName} label="Company Name">
                                    {this.state.company_name_list.map((k, i) => (
                                        <MenuItem key={k} value={this.state.company_name_list[i]}>
                                            {this.state.company_name_list[i]}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                    </div>
                    <div className='row' style={{ minHeight: '75vh' }}>
                        <div className='col-sm-6' >
                            <embed className='w-100 h-100 border' src={this.state.file_name} key={this.state.file_name}></embed>
                        </div>
                        <div className='col-sm-6' >
                            <div className='text-right'>
                                <Tooltip title="Download Report" placement='left-start' arrow>
                                    <Fab size="small" className='small_button text-white mr-2' onClick={() => this.downloadReport()} disabled={this.state.button_disabled} hidden={this.state.download_hidden}>
                                        <GetApp fontSize='small' />
                                    </Fab>
                                </Tooltip>
                            </div>
                            <TableContainer component={Paper} className='border mt-2'>
                                <Table size="medium">
                                    <colgroup>
                                        {/* <col width="50%" />
                                        <col width="50%" /> */}
                                    </colgroup>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell>
                                                <b>Currency</b>
                                            </TableCell>
                                            <TableCell align="left">
                                                {this.state.all_data['Currency']}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                <b>Fund Size (mil)</b>
                                            </TableCell>
                                            <TableCell align="left">
                                                {this.state.all_data['Fund Size']}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                <b>Principal Protection</b>
                                            </TableCell>
                                            <TableCell align="left">
                                                {this.state.all_data['Principal Protection']}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                <b>Launch Date</b>
                                            </TableCell>
                                            <TableCell align="left">
                                                {this.state.all_data['Launch Date']}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                <b>Asset Allocation - Bond</b>
                                            </TableCell>
                                            <TableCell align="left">
                                                {this.state.all_data['Asset Allocation - Bond']}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                <b>Asset Allocation - Equity</b>
                                            </TableCell>
                                            <TableCell align="left">
                                                {this.state.all_data['Asset Allocation - Equity']}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                <b>Asset Allocation - Cash</b>
                                            </TableCell>
                                            <TableCell align="left">
                                                {this.state.all_data['Asset Allocation - Cash']}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                <b>Performance ITD (Offer-bid)</b>
                                            </TableCell>
                                            <TableCell align="left">
                                                {this.state.all_data['Performance Itd Offer']}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                <b>Performance ITD (Bid-bid)</b>
                                            </TableCell>
                                            <TableCell align="left">
                                                {this.state.all_data['Performance Itd Bid']}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                <b>Benchmark</b>
                                            </TableCell>
                                            <TableCell align="left">
                                                {this.state.all_data['Benchmark']}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell colSpan='2' className='p-1'>
                                                <ListItem className="w-100" button onClick={() => this.onExpand(0)}>
                                                    <ListItemText><b>Top 10 Holdings</b></ListItemText>
                                                    {this.state.list_expand[0] ? <ExpandLess /> : <ExpandMore />}
                                                </ListItem>
                                                <Collapse in={this.state.list_expand[0]} timeout="auto">
                                                    <Table size="small">
                                                        <TableBody className='bg-light'>
                                                            {Object.keys(this.state.all_data['top_10_holdings']).map((k, i) => (
                                                                <TableRow key={k}>
                                                                    <TableCell>
                                                                        {i + 1}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {this.state.all_data['top_10_holdings'][i]['text']}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {this.state.all_data['top_10_holdings'][i]['value']}
                                                                    </TableCell>
                                                                </TableRow>
                                                            ))}
                                                        </TableBody>
                                                    </Table>
                                                </Collapse>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell colSpan='2' className='p-1'>
                                                <ListItem className="w-100" button onClick={() => this.onExpand(1)}>
                                                    <ListItemText><b>Ratings Allocation</b></ListItemText>
                                                    {this.state.list_expand[1] ? <ExpandLess /> : <ExpandMore />}
                                                </ListItem>
                                                <Collapse in={this.state.list_expand[1]} timeout="auto" className='text-center'>
                                                    <Table size="small">
                                                        <TableBody className='bg-light'>
                                                            {Object.keys(this.state.all_data['ratings_allocation']).map((k, i) => (
                                                                <TableRow key={k}>
                                                                    <TableCell>
                                                                        {this.state.all_data['ratings_allocation'][i]['text']}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {this.state.all_data['ratings_allocation'][i]['value']}
                                                                    </TableCell>
                                                                </TableRow>
                                                            ))}
                                                        </TableBody>
                                                    </Table>
                                                </Collapse>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                <b> ** Rating Allocation Note</b>
                                            </TableCell>
                                            <TableCell align="left">
                                                {this.state.all_data['ratings_allocation_note']}
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}

export default TabFundFactSheets;