import globalVariables from './globalVariables';

export default class urls {

    static BASE_URL = 'https://ocbc-backend-dot-yun-website.el.r.appspot.com/' //"http://127.0.0.1:5000/"

    static LOGIN = urls.BASE_URL + "login"
    static SIGNUP = urls.BASE_URL + "signup/"

    static TOKEN_REFRESH = urls.BASE_URL + "token_refresh/"

    static CNMS_FINANCIAL_SPREADING = urls.BASE_URL + "/ar/cnms_financial_spreading"
    static ANNUAL_REPORT_EXTRACTION = urls.BASE_URL + "ar/getInfo"
    static ANNUAL_REPORT_EXTRACTION_GET_HTML = urls.BASE_URL + "ar/highlight"

    static NEWS_READER = urls.BASE_URL + "extract"

    static CONTRACT_READER = urls.BASE_URL + "termsheet"

}