import React, { useEffect } from 'react';
import { useHistory } from "react-router-dom";
import PropTypes from 'prop-types';
import { AppBar, Tabs, Tab, IconButton, Tooltip, Typography, Box, TableBody, Paper, LinearProgress, FormControl, InputLabel, Select, MenuItem, Fab } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import TabContractReader from './TabContractReader';
import TabNewsReader from './TabNewsReader';
import TabFinancialParser from './TabFinancialParser';
import TabFinancialSummary from './TabFinancialSummary';
import yun_logo from '../images/yun_esg.jpg'
import './FileParser.css';
import TabLanguageTranslation from './TabLanguageTranslation';
import TabOurProducts from './TabOurProducts';
import { PowerSettingsNew, } from '@material-ui/icons'


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`nav-tabpanel-${index}`}
            aria-labelledby={`nav-tab-${index}`}
            {...other}>
            {value === index && (
                <Box p={3} className='p-0'>
                    <Typography component={'span'}>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
}));

export default function TabFinancials() {

    let history = useHistory();
    useEffect(() => {
        if (localStorage.getItem('LOGEDIN') !== 'true') {
            history.replace("/sign_in");
        }
    }, []);

    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const signOut = async () => {
        setTimeout(() => {
            localStorage.clear();
            history.replace("/sign_in");
        }, 500)
    };

    return (
        <div className={classes.root}>
            <div className='row'>
                <div className='col-md-8'>
                    <AppBar position="static" color='default' className='app_bar'>
                        <Tabs
                            indicatorColor="primary"
                            textColor="inherit"
                            variant="fullWidth"
                            scrollButtons="auto"
                            value={value}
                            TabIndicatorProps={{
                                style: { background: '#2f8d82' }
                            }}
                            onChange={handleChange} >
                            <Tab component="div" label={<span style={{ fontWeight: 'bold' }}>Financial Spreading for Credit</span>} />
                            <Tab component="div" label={<span style={{ fontWeight: 'bold' }}>Financial Summary</span>} />
                        </Tabs>
                    </AppBar>
                </div>
            </div>
            <div className='text-right'>
                <label className='border mt-2 w-50'></label>
            </div>
            <TabPanel value={value} index={0}>
                <TabFinancialParser />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <TabFinancialSummary />
            </TabPanel>
        </div>
    );
}