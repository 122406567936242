import React, { Component } from "react";
import 'bootstrap/dist/css/bootstrap.min.css'; //npm install --save bootstrap
import exportFromJSON from 'export-from-json'; // npm i --save export-from-json
import './FileParser.css';
import { ListItem, ListItemText, Button, Tooltip, Fab, CircularProgress, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper, LinearProgress, FormControl, InputLabel, Select, MenuItem, ListItemIcon, Collapse } from '@material-ui/core';
import { AttachFile, ExpandLess, ExpandMore, GetApp } from '@material-ui/icons'
import { get, post } from '../custom_libraries/serverRequests';
import urls from '../custom_libraries/urls';
import { Description } from "@material-ui/icons";

const COMPANY_NAME_LIST = ['HSBC (Statement - 1)', 'HSBC (Statement - 2)', 'HSBC (Statement - 4)', 'HSBC (Statement - 5)']
const SPREAD_SHEET = 'https://docs.google.com/spreadsheets/d/e/2PACX-1vRzctan2NtIVoH7ZrVr4h0i1w1zB4DMNxh1cyVKC2ggYLF3xro-YwuCirEEG3tPGTJdv4bJQSKGEfWu'
class TabBankStatements extends Component {

    constructor(props) {
        super(props);
        this.file_chooser = React.createRef();
    }

    state = {
        company_name_list: [],
        company_name: '',
        sheet_id: '',

        files: [],
        file_name: '',
        excel_name: '',

        is_progressar_hidden: true,
        spinner_hidden: true,
        button_disabled: false,
        download_hidden: true,
    }

    componentDidMount() {
        this.getCompanyName()

        // this.setState({all_data: fund_fact_sheets['Eastspring Investments - Asia Real Estate Multi Asset Income Fund']})
    }

    timeout = (delay) => {
        return new Promise(res => setTimeout(res, delay));
    }

    pickFile = (evt) => {
        let files = evt.target.files
        if (files.length > 0) {
            this.setState({ files: files })
            // this.uploadFile(files)
            this.displayStaticOutput(files)
        }
    }

    displayStaticOutput = (files) => {
        let file = files[0]
        let company_name = file.name.split('.pdf')[0]
        if (COMPANY_NAME_LIST.includes(company_name)) {
            // console.log('mmmm', company_name)
            let sheet_id = this.sheetIdByCompanyName(company_name)
            this.setState({ sheet_id: sheet_id });
            this.getOutputFiles(company_name, sheet_id)
        }
    }

    sheetIdByCompanyName = (company_name) => {
        let company_list = this.state.company_name_list.filter((c) => { return c.company_name == company_name });
        return (company_list[0].sheet_id)
    }

    formData = (files) => {
        let fd = new FormData();

        for (let i = 0; i < files.length; i++) {
            fd.append('files', files[i]);
        }

        return fd
    }

    uploadFile = async (files) => {
        if (files.length <= 0) {
            alert("Fill All Fields !!!")
            return
        }
        this.startProgressBar()

        let headers = {
            // "Authorization": `Bearer ${localStorage.getItem(globalVariables.AUTH_TOKEN)}`,
        }

        let post_data = this.formData(files)

        let response = await post('urls.SEND_FILES_URL', headers, post_data)

        if (response !== false) {
            // console.log('mmm', response, typeof response)
        }
        else {
            alert('Something went wrong. Please try again !!!')
        }
        this.stopProgressBar()
    }

    handleCompanyName = (evt) => {
        let i = evt.target.value;
        let company_name = this.state.company_name_list[i]['company_name'];
        let sheet_id = this.state.company_name_list[i]['sheet_id'];
        if (company_name !== null || company_name !== undefined || company_name !== '') {
            this.setState({ company_name: i });
            this.setState({ sheet_id: sheet_id });
            this.getOutputFiles(company_name, sheet_id)
        }
    }

    getCompanyName = async () => {
        this.startProgressBar()
        let company_name_list = [
            {
                'company_name': 'HSBC (Statement - 1)',
                'sheet_id': '0'
            },
            {
                'company_name': 'HSBC (Statement - 2)',
                'sheet_id': '1522179199'
            },
            {
                'company_name': 'HSBC (Statement - 4)',
                'sheet_id': '954141545'
            },
            {
                'company_name': 'HSBC (Statement - 5)',
                'sheet_id': '654723466'
            }
        ]
        // let company_name_list = ['HSBC (Statement - 1)', 'HSBC (Statement - 2)', 'HSBC (Statement - 4)', 'HSBC (Statement - 5)']
        this.setState({ company_name_list: company_name_list })
        this.stopProgressBar()
    }

    startProgressBar = () => {
        this.setState({ is_progressar_hidden: false });
        this.setState({ button_disabled: true })
        this.setState({ spinner_hidden: false })
    }

    stopProgressBar = () => {
        this.setState({ is_progressar_hidden: true });
        this.setState({ button_disabled: false })
        this.setState({ spinner_hidden: true })
    }

    getOutputFiles = async (company_name, sheet_id) => {
        this.startProgressBar()
        // Delay of 8 Seconds
        await this.timeout(5000);
        let file_name = `static/contract_reader/bank_statements/${company_name}.pdf`
        let excel_name = `${SPREAD_SHEET}/pubhtml?gid=${sheet_id}&single=true&widget=true&headers=false&gridlines=false`
        this.setState({
            file_name: file_name,
            excel_name: excel_name,
            download_hidden: false
        })
        this.stopProgressBar()
    }

    downloadReport = () => {
        window.open(`${SPREAD_SHEET}/pub?gid=${this.state.sheet_id}&single=true&output=xlsx`)
    }

    render() {
        return (
            <div className="d-flex justify-content-center align-items-center pt-0 pb-2">
                <div className="container-fluid border p-2">
                    <LinearProgress color="secondary" className='mb-2' hidden={this.state.is_progressar_hidden} />
                    <div className='row pb-3 pt-2'>
                        <div className='col-sm-12 text-right'>
                            {/* <FormControl size="small" variant="outlined" className="w-100" disabled={this.state.button_disabled}>
                                <InputLabel>Company Name</InputLabel>
                                <Select value={this.state.company_name} onChange={this.handleCompanyName} label="Company Name">
                                    {Object.keys(this.state.company_name_list).map((k, i) => (
                                        <MenuItem key={k} value={i}>
                                            {this.state.company_name_list[i]['company_name']}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl> */}
                            <input type='file' value='' ref={this.file_chooser} onChange={this.pickFile} accept=".pdf" hidden />
                            <Button
                                variant="contained"
                                size="small"
                                className="small_button pl-3"
                                onClick={() => this.file_chooser.current.click()}
                                disabled={this.state.button_disabled}>
                                <CircularProgress
                                    className="text-white mr-2"
                                    size={15}
                                    hidden={this.state.spinner_hidden}
                                />
                                <b>Choose File</b>
                                <AttachFile className="ml-2 pt-1 pb-1" />
                            </Button>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-6'></div>
                        <div className='col-md-6'>
                            <div className='text-right pb-2'>
                                <Tooltip title="Download Report" placement='left-start' arrow>
                                    <Fab size="small" className='small_button text-white mr-2' onClick={() => this.downloadReport()} disabled={this.state.button_disabled} hidden={this.state.download_hidden}>
                                        <GetApp fontSize='small' />
                                    </Fab>
                                </Tooltip>
                            </div>
                        </div>
                    </div>
                    <div className='row' style={{ minHeight: '75vh' }}>
                        <div className='col-sm-6' >
                            <embed className='w-100 h-100 border' src={this.state.file_name} key={this.state.file_name} />
                        </div>
                        <div className='col-sm-6' >
                            <embed className='w-100 h-100 border' src={this.state.excel_name} key={this.state.excel_name} />
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}

export default TabBankStatements;