import React, { Component } from "react";
import 'bootstrap/dist/css/bootstrap.min.css'; //npm install --save bootstrap
import exportFromJSON from 'export-from-json'; // npm i --save export-from-json
import './FileParser.css';
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper, LinearProgress, Tooltip, Fab, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import { GetApp } from '@material-ui/icons'
import { get, post } from '../custom_libraries/serverRequests';
import urls from '../custom_libraries/urls';



class TabFinancialParser extends Component {

    constructor(props) {
        super(props);
        this.file_chooser = React.createRef();
    }

    state = {
        company_name_list: [],
        company_name: '',
        header_data: {
            'values': []
        },
        data: [{
            'key': '',
            'values': [],
            'type': [],
            'lines': []
        }],
        html_data: '',
        is_progressar_hidden: true,
        button_disabled: false,
        download_hidden: true,
        table_body: 'table_non_clickable',
    }

    componentDidMount() {
        this.getCompanyName()
    }

    timeout = (delay) => {
        return new Promise(res => setTimeout(res, delay));
    }

    startProgressBar = () => {
        this.setState({ is_progressar_hidden: false });
        this.setState({ button_disabled: true })
        this.setState({ table_body: 'table_non_clickable' })
    }

    stopProgressBar = () => {
        this.setState({ is_progressar_hidden: true });
        this.setState({ button_disabled: false })
        this.setState({ table_body: 'table_clickable' })
    }

    getCompanyName = async () => {
        this.setState({ is_progressar_hidden: false });
        let headers = {
            'content-type': 'application/json',
        }

        let data = await get(urls.CNMS_FINANCIAL_SPREADING, headers)

        if (data !== false) {
            // console.log(data)
            this.setState({ company_name_list: data })
        }
        this.setState({ is_progressar_hidden: true });
    }

    handleCompanyName = (evt) => {
        let company_name = evt.target.value;
        if (company_name !== null || company_name !== undefined || company_name !== '') {
            this.setState({ company_name: company_name });
            this.annualReportExtraction(company_name)
        }
    }

    annualReportExtraction = async (company_name) => {

        this.startProgressBar()
        let headers = {
            'content-type': 'application/json',
        }

        let post_data = JSON.stringify({ 'company_name': company_name })

        let response = await post(urls.ANNUAL_REPORT_EXTRACTION, headers, post_data)

        // Delay of 5 Seconds
        await this.timeout(5000);

        if (response !== false) {
            // console.log(data)
            if (response['status']) {
                let data = response['data']
                this.setState({
                    header_data: data[0],
                    data: data.slice(1, data.length),
                    html_data: response['html'],
                    download_hidden: false
                })
            }
        }
        this.stopProgressBar()
    }

    jsonData = (type, lnum_list) => {
        let data = {
            'company_name': this.state.company_name,
            'type': type,
            'lnum_list': lnum_list
        }
        return JSON.stringify(data)
    }

    getHTMLFile = async (type, lnum_list) => {
        this.startProgressBar()
        let headers = {
            'content-type': 'application/json',
        }
        let post_data = this.jsonData(type, lnum_list)

        let data = await post(urls.ANNUAL_REPORT_EXTRACTION_GET_HTML, headers, post_data)

        if (data !== false) {
            // console.log(data)
            if (data.hasOwnProperty('html')) {
                this.setState({ html_data: data['html'] })
            }
        }
        this.stopProgressBar()
    }

    downloadReport = () => {
        let download_data = []
        let column_names = this.state.header_data['values']
        let all_data = this.state.data
        for (let i = 0; i < all_data.length; i++) {
            let single_data = {}
            single_data[''] = all_data[i]['key']
            for (let j = 0; j < column_names.length; j++) {
                single_data[column_names[j]] = all_data[i]['values'][j]
            }
            download_data.push(single_data)
        }

        const data = download_data
        const fileName = 'financial_parser_report'
        const exportType = 'xls'
        exportFromJSON({ data, fileName, exportType })
    }

    render() {
        return (
            <div className="d-flex justify-content-center align-items-center pt-0 pb-2">
                <div className="container-fluid border p-2">
                    <LinearProgress color="secondary" className='mb-2' hidden={this.state.is_progressar_hidden} />
                    <div className='row'>
                        <div className='col-md-8'>
                            <FormControl size="small" variant="outlined" className="w-100 mt-2 mb-2" disabled={this.state.button_disabled}>
                                <InputLabel>Company Name</InputLabel>
                                <Select value={this.state.company_name} onChange={this.handleCompanyName} label="Company Name">
                                    {this.state.company_name_list.map((k, i) => (
                                        <MenuItem key={i} value={this.state.company_name_list[i]['value']}>
                                            {this.state.company_name_list[i]['display']}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-8'>
                            <iframe src={"data:text/html," + encodeURIComponent(this.state.html_data)} className='w-100 h-100 min_height_iframe'></iframe>
                            {/* <div dangerouslySetInnerHTML={ { __html: this.state.html_data } }></div> */}
                        </div>
                        <div className='col-md-4' hidden={this.state.download_hidden}>
                            <div className='text-right pb-2'>
                                <Tooltip title="Download Report" placement='left-start' arrow>
                                    <Fab size="small" className='small_button text-white mr-2' onClick={() => this.downloadReport()} disabled={this.state.button_disabled}>
                                        <GetApp fontSize='small' />
                                    </Fab>
                                </Tooltip>
                            </div>
                            <TableContainer component={Paper} className='border'>
                                <Table size="medium">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>
                                            </TableCell>
                                            <TableCell align="right">
                                                <b>{this.state.header_data['values'][0]}</b>
                                            </TableCell>
                                            <TableCell align="right">
                                                <b>{this.state.header_data['values'][1]}</b>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody className={this.state.table_body}>
                                        {this.state.data.map((k, i) => (
                                            <TableRow hover className='pointer' key={i} onClick={() => this.getHTMLFile(this.state.data[i]['type'], this.state.data[i]['lines'])} >
                                                <TableCell>
                                                    <b>{this.state.data[i]['key']}</b>
                                                </TableCell>
                                                <TableCell align="right">
                                                    {this.state.data[i]['values'][0]}
                                                </TableCell>
                                                <TableCell align="right">
                                                    {this.state.data[i]['values'][1]}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}

export default TabFinancialParser;