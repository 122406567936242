import React, { Component } from "react";
import 'bootstrap/dist/css/bootstrap.min.css'; //npm install --save bootstrap
// import exportFromJSON from 'export-from-json'; // npm i --save export-from-json
import './FileParser.css';
import { Button, CircularProgress, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper, LinearProgress, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';

import { get, post } from '../custom_libraries/serverRequests';
import urls from '../custom_libraries/urls';



class TabFinancialSummary extends Component {

    constructor(props) {
        super(props);
        this.file_chooser = React.createRef();
    }

    state = {
        company_name_list: [],
        company_name: '',

        input_file: '',
        output_file: '',

        is_progressar_hidden: true,
        spinner_hidden: true,
        button_disabled: false,
    }

    componentDidMount() {
        this.getCompanyName()
    }

    timeout = (delay) => {
        return new Promise(res => setTimeout(res, delay));
    }

    handleCompanyName = (evt) => {
        let company_name = evt.target.value;
        if (company_name !== null || company_name !== undefined || company_name !== '') {
            this.setState({ company_name: company_name });
            this.getOutputFiles(company_name)
        }
    }

    getCompanyName = async () => {
        this.startProgressBar()
        let company_name_list = ['Aercap', 'Apple', 'CSC Holdings', 'Japfa', 'Koufu', 'Sembcorp']
        this.setState({ company_name_list: company_name_list })
        this.stopProgressBar()
    }

    startProgressBar = () => {
        this.setState({ is_progressar_hidden: false });
        this.setState({ button_disabled: true })
        this.setState({ spinner_hidden: false })
    }

    stopProgressBar = () => {
        this.setState({ is_progressar_hidden: true });
        this.setState({ button_disabled: false })
        this.setState({ spinner_hidden: true })
    }

    getOutputFiles = async (company_name) => {

        this.startProgressBar()
        // let headers = {
        //     'content-type': 'application/json',
        // }

        // let data = await get(urls.ANNUAL_REPORT_EXTRACTION, headers)

        // if (data !== false) {
        //     // console.log(data)
        // }

        // Delay of 8 Seconds
        await this.timeout(8000);

        this.setState({ input_file: 'static/financial_summary/' + company_name + '_input.pdf' })
        this.setState({ output_file: 'static/financial_summary/' + company_name + '.pdf' })

        this.stopProgressBar()
    }

    render() {
        return (
            <div className="d-flex justify-content-center align-items-center pt-0 pb-2">
                <div className="container-fluid border p-2">
                    <LinearProgress color="secondary" className='mb-2' hidden={this.state.is_progressar_hidden} />
                    <div className='row pb-2 pt-2'>
                        <div className='col-sm-6'>
                            <FormControl size="small" variant="outlined" className="w-100" disabled={this.state.button_disabled}>
                                <InputLabel>Company Name</InputLabel>
                                <Select value={this.state.company_name} onChange={this.handleCompanyName} label="Company Name">
                                    {this.state.company_name_list.map((k, i) => (
                                        <MenuItem key={k} value={this.state.company_name_list[i]}>
                                            {this.state.company_name_list[i]}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-sm-6'>
                            <b className='text-secondary pl-1'>Input</b>
                        </div>
                        <div className='col-sm-6'>
                            <b className='text-secondary pl-1'>Output</b>
                        </div>
                    </div>

                    <div className='row' style={{ minHeight: '75vh' }}>
                        <div className='col-sm-6' >
                            <embed className='w-100 h-100 border' src={this.state.input_file} key={this.state.input_file}></embed>
                        </div>
                        <div className='col-sm-6' >
                            <embed className='w-100 h-100 border' src={this.state.output_file} key={this.state.output_file}></embed>
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}

export default TabFinancialSummary;