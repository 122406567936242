import React from 'react'
import { BrowserRouter as Router, Route, Switch, Redirect, } from 'react-router-dom'

import './App.css'
import Home from './pages/Home'
import SignIn from './pages/SignIn'
import SignUp from './pages/SignUp'

class App extends React.Component {
    constructor(props) {
        super(props)
    }

    componentWillMount() {
        // let token_expiry = localStorage.getItem(globalVariables.TOKEN_EXPIRY)

        // if (token_expiry !== null) {
        //   if (Date.parse(token_expiry) < Date.now()) {
        //     localStorage.clear()
        //   }
        // }

        // if(localStorage.getItem(globalVariables.BASE_URL) === null){
        //   localStorage.setItem(globalVariables.BASE_URL, urls.DEFAULT_URL)
        //   localStorage.setItem(globalVariables.IP_ADDRESS, urls.IP)
        // }

    }

    render() {
        return (
            <Router>
                <Switch>
                    {/* <Route exact path="/login" component={Login} />
                    <Route exact path="/signup" component={SignUp} /> */}
                    {/* <Route exact path="/" render={() => <Redirect to={'/home'} />} /> */}
                    <Route exact path="/sign_up" component={SignUp} />
                    <Route exact path="/sign_in" component={SignIn} />
                    <Route exact path="/" component={Home} />
                    <Redirect to="/" />
                </Switch>
            </Router>
        )
    }
}

export default App
